import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios, { AxiosError } from "axios";

import { Loader } from "components/Loader";

interface EljurConnectParams {
    userId: string;
    role: string;
}

export const EljurConnectPage = () => {
    const location = useLocation();
    const history = useHistory();
    const params = useParams<EljurConnectParams>();
    const searchParams = new URLSearchParams(location.search);
    const authToken = searchParams.get("authToken");
    const vendor = searchParams.get("vendor");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (authToken && vendor && params.userId && params.role) {
            (async () => {
                try {
                    await axios.post("eljur/connect", {
                        codeToken: authToken,
                        vendor,
                        userId: params.userId,
                        role: params.role,
                    });

                    history.push("/dashboard");
                } catch (e: AxiosError | any) {
                    const errMessage = e?.response?.data?.message || "Ошибка привязки аккаунта ЭлЖур";
                    setErrorMessage(errMessage);
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [authToken, vendor, params, history]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="main">
            <div className="page auth-page">
                <header className="page__header">
                    <p className="page__title">Авторизация через ЭлЖур</p>
                </header>

                {!authToken && <p className="data-error">Передан некорректный токен пользователя</p>}
                {errorMessage && <p className="data-error">{errorMessage}</p>}
            </div>
        </div>
    );
};
