import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import { Alert } from "components/Alert";
import { Notice } from "components/Notice";
import { Input } from "components/forms/Input";
import { VK_APP_ID } from "config";
import { addVkScriptToPage } from "helpers/auth";
import { useFetchEljurUser } from "hooks/useQuery";
import { hideNotice, showAlert, showNotice } from "redux/actions/setAppActions";
import { selectUser } from "redux/authReducer";
import { getFormDataFromEljur } from "helpers/registration";
import terms from "static/docs/LSSR_terms_of_use.pdf";
import policy from "static/docs/policy.pdf";

const initialState = {
    sex: "1",
    name: "",
    patronymic: "",
    surname: "",
    birthday: "",
    email: "",
    password: "",
};

/**
 * @api {get} /pupil/:classId регистрация ученика
 * @apiGroup pupils
 * @apiName регистрация ученика
 *
 * @apiParam {String} classId ID класса
 *
 * @apiDescription <p>Страница регистрации ученика</p>
 * <p>Компонент <code>RegisterPupil.js</code> <a href="../client/src/pages/RegisterPupil.js">ссылка на файл</a></p>
 */

export const RegisterPupil = ({ location }) => {
    const { t } = useTranslation("registration");
    const dispatch = useDispatch();
    const history = useHistory();
    const classId = useParams().classId;
    const { dnevnikruUser } = useSelector(selectUser);
    const [group, setGroup] = useState(null);
    const [form, setForm] = useState(initialState);
    const { alert, notice } = useSelector((state) => state.app);
    const [firstCheckboxChecked, setFirstCheckboxChecked] = useState(false);
    const [secondCheckboxChecked, setSecondCheckboxChecked] = useState(false);
    const [thirdCheckboxChecked, setThirdCheckboxChecked] = useState(false);
    const [fourthCheckboxChecked, setFourthCheckboxChecked] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [incorrectFields, setIncorrectFields] = useState([]);
    const formFields = {
        surname: t("surname"),
        name: t("name"),
        birthday: t("birthday"),
        email: t("login"),
        password: t("password"),
    };
    const searchParams = new URLSearchParams(location.search);
    const authToken = searchParams.get("authToken");
    const vendor = searchParams.get("vendor");
    const { eljurUserData, eljurUserError } = useFetchEljurUser(authToken, vendor);

    function firstCheckboxHandler() {
        setFirstCheckboxChecked(!firstCheckboxChecked);
    }

    function secondCheckboxHandler() {
        setSecondCheckboxChecked(!secondCheckboxChecked);
    }

    function thirdCheckboxHandler() {
        setThirdCheckboxChecked(!thirdCheckboxChecked);
    }

    function fourthCheckboxHandler() {
        setFourthCheckboxChecked(!fourthCheckboxChecked);
    }

    useEffect(() => {
        if (classId) {
            (async () => {
                try {
                    const { data } = await axios.get(`classes/${classId}`);
                    setGroup(data);

                    addVkScriptToPage();
                } catch (err) {
                    dispatch(
                        showAlert({
                            type: "error",
                            text: t("class_error"),
                        })
                    );

                    setTimeout(() => {
                        window.location.href = "/";
                    }, 3000);
                }
            })();
        }
    }, [dispatch, classId, t]);

    useEffect(() => {
        if (eljurUserData) {
            if (eljurUserData.role !== "pupil" || !eljurUserData.userData) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: t("eljur_user_error", { ns: "translation" }),
                    })
                );
            } else {
                const userData = getFormDataFromEljur(eljurUserData.userData);
                setForm((prevState) => ({ ...prevState, ...userData }));
            }
        }
    }, [eljurUserData, dispatch, t]);

    useEffect(() => {
        if (eljurUserError) {
            dispatch(
                showAlert({
                    type: "error",
                    text: t("eljur_user_error", { ns: "translation" }),
                })
            );
        }
    }, [eljurUserError, dispatch, t]);

    const VkAuthHandler = () => {
        //eslint-disable-next-line no-undef
        VK.init({
            apiId: VK_APP_ID,
        });

        //eslint-disable-next-line no-undef
        VK.Auth.login((res) => {
            if (res?.session) {
                const user = res.session?.user;

                if (user?.id) {
                    setForm({
                        ...form,
                        vkId: user.id,
                    });
                }
            }
        });
    };

    const register = async () => {
        if (firstCheckboxChecked && secondCheckboxChecked && thirdCheckboxChecked && fourthCheckboxChecked) {
            const errorFields = [];
            const forbiddenSymbols = /[%;="*?#&()_~]/gi;
            if (form.surname.length < 2 || form.surname.length > 20 || forbiddenSymbols.test(form.surname))
                errorFields.push("surname");
            if (form.name.length < 2 || form.name.length > 20 || forbiddenSymbols.test(form.name))
                errorFields.push("name");
            if (!moment(form.birthday, "DD.MM.YYYY", true).isBetween(-1577934000000, moment.now())) {
                errorFields.push("birthday");
            }
            if (!form.email) errorFields.push("email");
            if (form.password.length < 10) errorFields.push("password");

            if (!errorFields.length) {
                try {
                    const data = { ...form };
                    const ISODate = moment(data.birthday, "DD.MM.YYYY", true).toISOString();

                    if (ISODate) {
                        data.birthday = ISODate;
                    }

                    const postData = {
                        ...data,
                        classId,
                        psychId: group?.psych?._id,
                    };

                    if (dnevnikruUser) {
                        postData.dnevnikruPersonId = dnevnikruUser.personId_str;
                        postData.dnevnikruAccessToken = dnevnikruUser.accessToken;
                    }

                    await axios.post("pupil/register", postData);

                    setForm(initialState);

                    dispatch(
                        showNotice(`${t("pupil_success_message")} ${form.vkId ? t("pupil_success_message_vk") : ""}`)
                    );
                    setIsSuccess(true);
                } catch (e) {
                    if (e.response?.data?.message) {
                        dispatch(showNotice(e.response.data.message));
                    } else {
                        dispatch(showNotice(e.message));
                    }
                }
            } else {
                const incorrectFieldsMessage = errorFields.map((item) => formFields[item]).join("\n");
                setIncorrectFields(errorFields);
                dispatch(
                    showAlert({
                        type: "error",
                        text: `${t("incorrectly_filled_fields", {
                            ns: "translation",
                        })}:\n\n${incorrectFieldsMessage}`,
                    })
                );
            }
        }
    };

    const changeHandler = (e) => {
        const { name, value } = e.target;

        if (incorrectFields.includes(name)) {
            setIncorrectFields(incorrectFields.filter((item) => item !== name));
        }

        if (["name", "surname"].includes(name) && value.length > 20) {
            return;
        }

        const newValue = ["name", "surname", "patronymic", "email", "password"].includes(name) ? value.trim() : value;

        setForm({
            ...form,
            [name]: newValue,
        });
    };

    const hideNoticeHandler = () => {
        dispatch(hideNotice());

        if (isSuccess) {
            window.location.href = "/";
        }
    };

    const showPasswordHandler = () => {
        setIsShowPassword(!isShowPassword);
    };

    // Фикс для правильных редиректов. У новых учеников назад - главная страница. У психолога - настройки класса.
    const goBackHandler = () => {
        if (location.state?.prevPath) {
            history.goBack();
            return;
        }
        history.push("/");
    };

    return (
        <div className="main">
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            {!!notice && <Notice text={notice.text} closeHandler={hideNoticeHandler} />}

            <div className="page auth-page">
                <header className="page__header">
                    <button
                        onClick={goBackHandler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title">{t("registration")}</p>
                </header>

                <div className="auth-page__wrapper">
                    <div className="auth-page__header">
                        <h3>{t("registration")}</h3>
                        <Link to={`/pupil/login/${classId || ""}`} className="auth-page__header-btn">
                            {t("got_account")}
                        </Link>
                    </div>
                    {group && (
                        <div className="auth-page__info">
                            <div className="auth-page__info-item">
                                <div>
                                    <p>
                                        {group.psych.name} {group.psych.patronymic}
                                    </p>
                                    <p className="auth-page__info-desc">{t("your_psychologist")}</p>
                                </div>
                            </div>
                            <div className="auth-page__info-item">
                                <p className="auth-page__info-title">{t("school")}</p>
                                <p>{group.school.name}</p>
                            </div>
                            <div className="auth-page__info-item">
                                <p className="auth-page__info-title">{t("class")}</p>
                                <p>
                                    {group.number} {group.letter}
                                </p>
                            </div>
                        </div>
                    )}
                    <div className="auth-page__input-group auth-page__input-group_row">
                        <div className="input-field">
                            <input
                                id="m"
                                type="radio"
                                name="sex"
                                value="1"
                                checked={form.sex === "1"}
                                onChange={changeHandler}
                            />
                            <label htmlFor="m">{t("male")}</label>
                        </div>
                        <div className="input-field">
                            <input
                                id="f"
                                type="radio"
                                name="sex"
                                value="2"
                                checked={form.sex === "2"}
                                onChange={changeHandler}
                            />
                            <label htmlFor="f">{t("female")}</label>
                        </div>
                    </div>

                    <div className="auth-page__input-group">
                        <Input
                            id="surname"
                            type="text"
                            name="surname"
                            placeholder={t("surname")}
                            onChange={changeHandler}
                            value={form.surname}
                            isError={incorrectFields.includes("surname")}
                        />
                        <Input
                            id="name"
                            type="text"
                            name="name"
                            onChange={changeHandler}
                            placeholder={t("name")}
                            value={form.name}
                            isError={incorrectFields.includes("name")}
                        />
                        <Input
                            id="patronymic"
                            type="text"
                            name="patronymic"
                            onChange={changeHandler}
                            placeholder={t("patronymic")}
                            value={form.patronymic}
                            isError={incorrectFields.includes("patronymic")}
                        />
                        <Input
                            id="birthday"
                            type="text"
                            name="birthday"
                            placeholder={t("birthday")}
                            onChange={changeHandler}
                            value={form.birthday}
                            isError={incorrectFields.includes("birthday")}
                            mask={Date}
                            maskOptions={{
                                min: new Date(1950, 0, 1),
                                max: new Date(),
                                unmask: true,
                            }}
                        />
                        <Input
                            id="email"
                            type="text"
                            name="email"
                            placeholder={t("login")}
                            onChange={changeHandler}
                            isError={incorrectFields.includes("email")}
                            value={form.email}
                        />
                        <Input
                            id="password"
                            type={`${isShowPassword ? "text" : "password"}`}
                            name="password"
                            placeholder={t("password")}
                            onChange={changeHandler}
                            value={form.password}
                            isError={incorrectFields.includes("password")}
                        />
                        <div className="animatedCheckbox animatedCheckbox__password">
                            <div
                                onClick={showPasswordHandler}
                                className={`checkbox ${isShowPassword ? "checked" : ""}`}
                            >
                                <FaCheck style={{ filter: "brightness(0) invert(1)" }} />
                            </div>
                            <p>{t("show_password")}</p>
                        </div>
                        <p style={{ textAlign: "left", opacity: 0.5 }}>{t("password_length")}</p>
                    </div>

                    <div className="auth-page__vk-block">
                        <button className="vk-button" onClick={VkAuthHandler} disabled={form.vkId}>
                            {form.vkId ? (
                                <span>{t("link_vk_success_button")}</span>
                            ) : (
                                <span>{t("link_vk_button")}</span>
                            )}
                        </button>

                        <p>{t("link_vk_button_text")}</p>
                    </div>

                    <div className="landing-contacts__col">
                        <div className="animatedCheckbox">
                            <div
                                style={
                                    firstCheckboxChecked ? { backgroundColor: "#2330BA" } : { backgroundColor: "white" }
                                }
                                onClick={firstCheckboxHandler}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>
                                {t("terms_of_use_1a")}{" "}
                                <a href={terms} target="_blank" rel="noreferrer">
                                    {t("user_agreement_1")}
                                </a>{" "}
                                {t("terms_of_use_1b")}
                            </p>
                        </div>

                        <div className="animatedCheckbox">
                            <div
                                style={
                                    secondCheckboxChecked
                                        ? { backgroundColor: "#2330BA" }
                                        : { backgroundColor: "white" }
                                }
                                onClick={secondCheckboxHandler}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>
                                {t("terms_of_use_1a")}{" "}
                                <a href={policy} target="_blank" rel="noreferrer">
                                    {t("policy_of_personal_data")}
                                </a>
                            </p>
                        </div>

                        <div className="animatedCheckbox">
                            <div
                                style={
                                    thirdCheckboxChecked ? { backgroundColor: "#2330BA" } : { backgroundColor: "white" }
                                }
                                onClick={thirdCheckboxHandler}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>
                                {t("terms_of_use_2a")}{" "}
                                <a href={terms} target="_blank" rel="noreferrer">
                                    {t("user_agreement_2")}
                                </a>{" "}
                                {t("terms_of_use_2b")}
                            </p>
                        </div>

                        <div className="animatedCheckbox">
                            <div
                                style={
                                    fourthCheckboxChecked
                                        ? { backgroundColor: "#2330BA" }
                                        : { backgroundColor: "white" }
                                }
                                onClick={fourthCheckboxHandler}
                                className="checkbox"
                            >
                                <FaCheck
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                />
                            </div>
                            <p>{t("terms_of_use_3")}</p>
                        </div>
                    </div>

                    <p style={{ paddingBottom: "2rem", paddingTop: "2rem" }}>
                        {t("terms_of_use_4a")}{" "}
                        <a href={policy} target="_blank" rel="noreferrer">
                            {t("policy_of_personal_data")}
                        </a>{" "}
                        {t("terms_of_use_4b")}{" "}
                        <a href={terms} target="_blank" rel="noreferrer">
                            {t("user_agreement_2")}
                        </a>
                    </p>

                    <button
                        style={
                            firstCheckboxChecked &&
                            secondCheckboxChecked &&
                            thirdCheckboxChecked &&
                            fourthCheckboxChecked
                                ? {}
                                : { background: "gray", boxShadow: "none" }
                        }
                        onClick={register}
                        className="main-btn auth-page__btn"
                    >
                        {t("registration_button")}
                    </button>
                </div>
            </div>
        </div>
    );
};
