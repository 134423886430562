import { useQuery } from "@tanstack/react-query";

import { EljurUserResponse, fetchEljurUser } from "api/eljur/fetchEljurUser";

export function useFetchEljurUser(authToken?: string | null, vendor?: string | null) {
    const { data, isLoading, error } = useQuery<EljurUserResponse, Error>({
        queryKey: ["eljur/user", authToken, vendor],
        queryFn: () => fetchEljurUser(authToken || "", vendor || ""),
        enabled: !!authToken,
    });

    return {
        eljurUserData: data,
        eljurUserError: error,
        eljurUserIsLoading: isLoading,
    };
}
