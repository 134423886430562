import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { SureAlert } from "components/SureAlert";
import { RegisterPsychRegionSelect } from "components/register/RegisterPsychRegionSelect";
import { psychRegisterFormsValidators } from "helpers/formsValidators";
import { getFormDataFromEljur } from "helpers/registration.ts";
import { validatePhone } from "helpers/validators";
import { showAlert, showNotice } from "redux/actions/setAppActions";
import { RegisterPsychPhoneVerification } from "./RegisterPsychPhoneVerification";
import { Loader } from "components/Loader";
import { useFetchRegions } from "hooks/useQuery/regions/useFetchRegions";
import { RegisterPsychInstitutionSelect } from "./RegisterPsychInstitutionSelect";
import { RegisterPsychMainForm } from "./RegisterPsychMainForm";
import { useFetchEljurUser } from "hooks/useQuery";
import terms from "static/docs/LSSR_terms_of_use.pdf";
import policy from "static/docs/policy.pdf";

const initialState = {
    sex: "1",
    login: "",
    email: "",
    name: "",
    surname: "",
    patronymic: "",
    birthday: "",
    region: "",
    city: "",
    phone: "",
    institution: "",
    institutionID: "",
};

const minPsychBirthYear = new Date().getFullYear() - 18;

export const RegisterPsychForm = ({ setIsSuccess, showHeader, hideHeader }) => {
    const { t } = useTranslation(["registration", "translation"]);
    const dispatch = useDispatch();
    const history = useHistory();
    const { regions } = useFetchRegions();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const authToken = searchParams.get("authToken");
    const vendor = searchParams.get("vendor");
    const { eljurUserData, eljurUserError, eljurUserIsLoading } = useFetchEljurUser(authToken, vendor);
    const { loading } = useSelector((state) => state.app);
    const [form, setForm] = useState(initialState);
    const [firstCheckboxChecked, setFirstCheckboxChecked] = useState(false);
    const [secondCheckboxChecked, setSecondCheckboxChecked] = useState(false);
    const [thirdCheckboxChecked, setThirdCheckboxChecked] = useState(false);
    const [fourthCheckboxChecked, setFourthCheckboxChecked] = useState(false);
    const [incorrectFields, setIncorrectFields] = useState([]);
    const [isSetRegion, setIsSetRegion] = useState(false);
    const [isSetPhoneConfirm, setIsSetPhoneConfirm] = useState(false);
    const [isSetInstitution, setIsSetInstitution] = useState(false);
    const [isPhoneConfirmSureAlert, setIsPhoneConfirmSureAlert] = useState(false);
    const [isPhoneConfirmed, setIsPhoneConfirmed] = useState(false);
    const [regionID, setRegionID] = useState(null);
    const [isInstitutionNotFound, setIsInstitutionNotFound] = useState(false);
    const formFields = {
        surname: t("surname"),
        name: t("name"),
        birthday: t("birthday"),
        login: t("login"),
        email: t("email"),
        phone: t("phone"),
        region: t("region"),
        institution: t("institution"),
        phoneVerification: t("phone_verification_title"),
    };

    useEffect(() => {
        if (eljurUserData) {
            if (eljurUserData.role !== "psych" || !eljurUserData.userData) {
                dispatch(
                    showAlert({
                        type: "error",
                        text: t("eljur_user_error", { ns: "translation" }),
                    })
                );
            } else {
                const userData = getFormDataFromEljur(eljurUserData.userData);
                setForm((prevState) => ({ ...prevState, ...userData }));
            }
        }
    }, [eljurUserData, dispatch, t]);

    useEffect(() => {
        if (eljurUserError) {
            dispatch(
                showAlert({
                    type: "error",
                    text: t("eljur_user_error", { ns: "translation" }),
                })
            );
        }
    }, [eljurUserError, dispatch, t]);

    useEffect(() => {
        const savedForm = localStorage.getItem("psychRegisterForm");

        if (savedForm) {
            const parsedForm = JSON.parse(savedForm);

            if (parsedForm?.region || parsedForm?.city) {
                parsedForm.region = "";
                parsedForm.city = "";
                parsedForm.institution = "";

                delete parsedForm.institutionID;
            }

            setForm(parsedForm);
        }
    }, []);

    useEffect(() => {
        if (searchParams.size > 0) {
            const step = searchParams.get("step");

            if (step === "region") {
                setIsSetRegion(true);
                hideHeader();
            } else if (step === "phone" && !isPhoneConfirmed) {
                setIsSetPhoneConfirm(true);
                hideHeader();
            } else if (step === "institution") {
                setIsSetInstitution(true);
                hideHeader();
            }
        } else {
            setIsSetRegion(false);
            setIsSetPhoneConfirm(false);
            setIsSetInstitution(false);
            showHeader();
        }
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    const registerHandler = async () => {
        if (firstCheckboxChecked && secondCheckboxChecked && thirdCheckboxChecked && fourthCheckboxChecked) {
            if (form.birthday && moment(form.birthday, "DD.MM.YYYY", true).year() >= minPsychBirthYear) {
                dispatch(
                    showNotice(
                        "Здесь регистрация только для сотрудников, чтобы получить логин и пароль обратитесь к вашему Педагогу-психологу"
                    )
                );

                return;
            }

            const errorFields = psychRegisterFormsValidators(form, isPhoneConfirmed, isInstitutionNotFound);

            if (!errorFields.length) {
                try {
                    const data = { ...form };
                    let ISODate = moment(data.birthday, "DD.MM.YYYY", true).toISOString();
                    if (ISODate) {
                        data.birthday = ISODate;
                    }

                    if (regionID) {
                        data.regionID = regionID;
                    }

                    if (isInstitutionNotFound) {
                        data.isInstitutionNotFound = true;
                    }

                    if (!regionID && !data.institutionID) {
                        delete data.institutionID;
                    }

                    await axios.post("psych/register", { ...data });

                    setForm(initialState);
                    dispatch(showNotice(t("psychologist_success_message")));
                    localStorage.removeItem("psychRegisterForm");
                    setIsSuccess(true);
                } catch (e) {
                    if (e.response?.data?.message) {
                        dispatch(showNotice(e.response.data.message));
                    } else {
                        dispatch(showNotice(e.message));
                    }
                }
            } else {
                const incorrectFieldsMessage = errorFields.map((item) => formFields[item]).join("\n");
                setIncorrectFields(errorFields);
                dispatch(
                    showAlert({
                        type: "error",
                        text: `${t("incorrectly_filled_fields", {
                            ns: "translation",
                        })}:\n\n${incorrectFieldsMessage}`,
                    })
                );
            }
        }
    };

    const changeHandler = (e) => {
        let { name, value } = e.target;

        if (incorrectFields.includes(name)) {
            setIncorrectFields(incorrectFields.filter((item) => item !== name));
        }

        if (["name", "surname"].includes(name) && value.length > 20) {
            return;
        }

        const newValue = ["institution", "birthday", "phone"].includes(name) ? value : value.trim();
        const formData = {
            ...form,
            [name]: newValue,
        };

        localStorage.setItem("psychRegisterForm", JSON.stringify(formData));
        setForm(formData);
    };

    const setRegionHandler = (region, city) => {
        if (incorrectFields.includes("region")) {
            setIncorrectFields(incorrectFields.filter((item) => item !== "region"));
        }
        const formData = {
            ...form,
            region,
            city,
        };

        if (regions) {
            const findedRegion = regions.find((item) => item.name.toLowerCase() === region.toLowerCase());

            if (findedRegion) {
                formData.institution = "";
                formData.institutionID = "";
            }

            setRegionID(findedRegion?._id || null);
        }

        localStorage.setItem("psychRegisterForm", JSON.stringify(formData));
        setForm(formData);
        history.push("/psych/register");
    };

    const handleSetInstitution = (institutionName, institutionID) => {
        if (incorrectFields.includes("institution")) {
            setIncorrectFields(incorrectFields.filter((item) => item !== "institution"));
        }

        const formData = {
            ...form,
            institution: institutionName,
            institutionID,
        };

        if (institutionName === null && institutionID === null) {
            formData.institution = "Учреждение не найдено";
            formData.institutionID = "";
            setIsInstitutionNotFound(true);
        } else {
            formData.institution = institutionName;
            formData.institutionID = institutionID;
            setIsInstitutionNotFound(false);
        }

        localStorage.setItem("psychRegisterForm", JSON.stringify(formData));
        setForm(formData);

        history.push("/psych/register");
    };

    const showPhoneConfirmFormHandler = () => {
        setIsPhoneConfirmSureAlert(true);
    };

    const confirmPhoneHandler = () => {
        setIsPhoneConfirmSureAlert(false);
        history.push("/psych/register?step=phone");
    };

    const successPhoneVerification = () => {
        setIsPhoneConfirmed(true);
        history.push("/psych/register");
    };

    if (isSetRegion) {
        return <RegisterPsychRegionSelect setRegion={setRegionHandler} />;
    }

    if (isSetPhoneConfirm) {
        return <RegisterPsychPhoneVerification phone={form.phone} setPhoneVerification={successPhoneVerification} />;
    }

    if (isSetInstitution) {
        return <RegisterPsychInstitutionSelect regionId={regionID} onSelectInstitution={handleSetInstitution} />;
    }

    return (
        <>
            {isPhoneConfirmSureAlert && (
                <SureAlert
                    title={t("phone_verification_title")}
                    text={t("phone_verification_text")}
                    submitText={t("phone_verification_btn")}
                    handleClose={() => setIsPhoneConfirmSureAlert(false)}
                    handleSubmit={confirmPhoneHandler}
                />
            )}

            {loading || (authToken && eljurUserIsLoading) ? (
                <Loader />
            ) : (
                <RegisterPsychMainForm
                    form={form}
                    incorrectFields={incorrectFields}
                    isPhoneConfirmed={isPhoneConfirmed}
                    minPsychBirthYear={minPsychBirthYear}
                    regionID={regionID}
                    firstCheckboxChecked={firstCheckboxChecked}
                    secondCheckboxChecked={secondCheckboxChecked}
                    thirdCheckboxChecked={thirdCheckboxChecked}
                    fourthCheckboxChecked={fourthCheckboxChecked}
                    terms={terms}
                    policy={policy}
                    changeHandler={changeHandler}
                    showPhoneConfirmFormHandler={showPhoneConfirmFormHandler}
                    validatePhone={validatePhone}
                    setFirstCheckboxChecked={setFirstCheckboxChecked}
                    setSecondCheckboxChecked={setSecondCheckboxChecked}
                    setThirdCheckboxChecked={setThirdCheckboxChecked}
                    setFourthCheckboxChecked={setFourthCheckboxChecked}
                    registerHandler={registerHandler}
                />
            )}
        </>
    );
};
