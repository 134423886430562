import React from "react";
import { FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface TermsAndConditionsProps {
    firstCheckboxChecked: boolean;
    secondCheckboxChecked: boolean;
    thirdCheckboxChecked: boolean;
    fourthCheckboxChecked: boolean;
    setFirstCheckboxChecked: (value: boolean) => void;
    setSecondCheckboxChecked: (value: boolean) => void;
    setThirdCheckboxChecked: (value: boolean) => void;
    setFourthCheckboxChecked: (value: boolean) => void;
    terms: string;
    policy: string;
    onRegister: () => void;
}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
    firstCheckboxChecked,
    secondCheckboxChecked,
    thirdCheckboxChecked,
    fourthCheckboxChecked,
    setFirstCheckboxChecked,
    setSecondCheckboxChecked,
    setThirdCheckboxChecked,
    setFourthCheckboxChecked,
    terms,
    policy,
    onRegister,
}) => {
    const { t } = useTranslation(["registration"]);

    const checkboxStyle = (isChecked: boolean) => ({
        backgroundColor: isChecked ? "#2330BA" : "white",
    });

    return (
        <>
            <div className="landing-contacts__col">
                <div className="animatedCheckbox">
                    <div
                        style={checkboxStyle(firstCheckboxChecked)}
                        onClick={() => setFirstCheckboxChecked(!firstCheckboxChecked)}
                        className="checkbox"
                    >
                        <FaCheck
                            style={{
                                filter: "brightness(0) invert(1)",
                            }}
                        />
                    </div>
                    <p>
                        {t("terms_of_use_1a")}{" "}
                        <a href={terms} target="_blank" rel="noreferrer">
                            {t("user_agreement_1")}
                        </a>{" "}
                        {t("terms_of_use_1b")}
                    </p>
                </div>

                <div className="animatedCheckbox">
                    <div
                        style={checkboxStyle(secondCheckboxChecked)}
                        onClick={() => setSecondCheckboxChecked(!secondCheckboxChecked)}
                        className="checkbox"
                    >
                        <FaCheck
                            style={{
                                filter: "brightness(0) invert(1)",
                            }}
                        />
                    </div>
                    <p>
                        {t("terms_of_use_1a")}{" "}
                        <a href={policy} target="_blank" rel="noreferrer">
                            {t("policy_of_personal_data")}
                        </a>
                    </p>
                </div>

                <div className="animatedCheckbox">
                    <div
                        style={checkboxStyle(thirdCheckboxChecked)}
                        onClick={() => setThirdCheckboxChecked(!thirdCheckboxChecked)}
                        className="checkbox"
                    >
                        <FaCheck
                            style={{
                                filter: "brightness(0) invert(1)",
                            }}
                        />
                    </div>
                    <p>
                        {t("terms_of_use_2a")}{" "}
                        <a href={terms} target="_blank" rel="noreferrer">
                            {t("user_agreement_2")}
                        </a>{" "}
                        {t("terms_of_use_2b")}
                    </p>
                </div>

                <div className="animatedCheckbox">
                    <div
                        style={checkboxStyle(fourthCheckboxChecked)}
                        onClick={() => setFourthCheckboxChecked(!fourthCheckboxChecked)}
                        className="checkbox"
                    >
                        <FaCheck
                            style={{
                                filter: "brightness(0) invert(1)",
                            }}
                        />
                    </div>
                    <p>{t("terms_of_use_3")}</p>
                </div>
            </div>
            <p style={{ paddingBottom: "2rem", paddingTop: "2rem" }}>
                {t("terms_of_use_4a")}{" "}
                <a href={policy} target="_blank" rel="noreferrer">
                    {t("policy_of_personal_data")}
                </a>{" "}
                {t("terms_of_use_4b")}{" "}
                <a href={terms} target="_blank" rel="noreferrer">
                    {t("user_agreement_2")}
                </a>
            </p>
            <button
                style={
                    firstCheckboxChecked && secondCheckboxChecked && thirdCheckboxChecked && fourthCheckboxChecked
                        ? {}
                        : { background: "gray", boxShadow: "none" }
                }
                onClick={onRegister}
                className="main-btn auth-page__btn"
            >
                {t("registration_button")}
            </button>
        </>
    );
};
