import axios from "axios";
import { catchAsync } from "../catchAsync";

export interface EljurUser {
    eljurId: string;
    eljurVendor: string;
    eljurVendorId: number;
    eljurSchoolName: string;
    surname: string;
    name: string;
    patronymic: string;
    email: string;
    sex: "1" | "2";
    classId?: string;
}

export interface EljurUserResponse {
    role: "psych" | "pupil";
    isUserRegistered: false;
    userData: EljurUser;
}

export const fetchEljurUser = (authToken: string, vendor: string) => {
    return catchAsync(async () => {
        const { data } = await axios.get("eljur/user", {
            params: { authToken, vendor },
        });
        return data;
    });
};
