import { EljurUser } from "api/eljur/fetchEljurUser";

export const getFormDataFromEljur = (userData: EljurUser) => {
    const { eljurId, eljurVendor, eljurVendorId, eljurSchoolName, name, patronymic, surname, email, sex } = userData;

    return {
        eljurId,
        eljurVendor,
        eljurVendorId,
        eljurSchoolName,
        name,
        patronymic,
        surname,
        email,
        sex,
    };
};
