import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { classNames } from "helpers/classNames";
import { IMaskInput } from "react-imask";
import { Input } from "components/forms/Input";
import { TermsAndConditions } from "./TermsAndConditions";
import checkIcon from "static/img/check-circle.svg";

interface RegisterFormProps {
    form: {
        sex: string;
        surname: string;
        name: string;
        patronymic: string;
        birthday: string;
        login: string;
        email: string;
        phone: string;
        region: string;
        city: string;
        institution: string;
        institutionID?: string;
    };
    incorrectFields: string[];
    isPhoneConfirmed: boolean;
    minPsychBirthYear: number;
    regionID?: string;
    firstCheckboxChecked: boolean;
    secondCheckboxChecked: boolean;
    thirdCheckboxChecked: boolean;
    fourthCheckboxChecked: boolean;
    terms: string;
    policy: string;
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    showPhoneConfirmFormHandler: () => void;
    validatePhone: (phone: string) => boolean;
    setFirstCheckboxChecked: (value: boolean) => void;
    setSecondCheckboxChecked: (value: boolean) => void;
    setThirdCheckboxChecked: (value: boolean) => void;
    setFourthCheckboxChecked: (value: boolean) => void;
    registerHandler: () => void;
}

export const RegisterPsychMainForm: React.FC<RegisterFormProps> = ({
    form,
    incorrectFields,
    isPhoneConfirmed,
    minPsychBirthYear,
    regionID,
    firstCheckboxChecked,
    secondCheckboxChecked,
    thirdCheckboxChecked,
    fourthCheckboxChecked,
    terms,
    policy,
    changeHandler,
    showPhoneConfirmFormHandler,
    validatePhone,
    setFirstCheckboxChecked,
    setSecondCheckboxChecked,
    setThirdCheckboxChecked,
    setFourthCheckboxChecked,
    registerHandler,
}) => {
    const { t } = useTranslation(["registration", "translation"]);
    const history = useHistory();

    return (
        <>
            <div className="auth-page__input-group auth-page__input-group_row">
                <div className="input-field">
                    <input
                        id="m"
                        type="radio"
                        name="sex"
                        value="1"
                        checked={form.sex === "1"}
                        onChange={changeHandler}
                    />
                    <label htmlFor="m">{t("male")}</label>
                </div>
                <div className="input-field">
                    <input
                        id="f"
                        type="radio"
                        name="sex"
                        value="2"
                        checked={form.sex === "2"}
                        onChange={changeHandler}
                    />
                    <label htmlFor="f">{t("female")}</label>
                </div>
            </div>
            <div className="auth-page__input-group">
                <Input
                    type="text"
                    name="surname"
                    placeholder={t("surname")}
                    onChange={changeHandler}
                    value={form.surname}
                    isError={incorrectFields.includes("surname")}
                    onClick={() => {}}
                    otherProps={{}}
                />
                <Input
                    type="text"
                    name="name"
                    onChange={changeHandler}
                    placeholder={t("name")}
                    value={form.name}
                    isError={incorrectFields.includes("name")}
                    onClick={() => {}}
                    otherProps={{}}
                />
                <Input
                    type="text"
                    name="patronymic"
                    onChange={changeHandler}
                    placeholder={t("patronymic")}
                    value={form.patronymic}
                    isError={incorrectFields.includes("patronymic")}
                    onClick={() => {}}
                    otherProps={{}}
                />
                <Input
                    type="text"
                    name="birthday"
                    placeholder={t("birthday")}
                    onChange={changeHandler}
                    value={form.birthday}
                    isError={incorrectFields.includes("birthday")}
                    //@ts-ignore
                    mask={Date}
                    maskOptions={{
                        min: new Date(1950, 0, 1),
                        max: new Date(minPsychBirthYear, 0, 1),
                        unmask: true,
                    }}
                    onClick={() => {}}
                    otherProps={{}}
                />
                <Input
                    type="text"
                    name="login"
                    placeholder={t("login")}
                    onChange={changeHandler}
                    value={form.login}
                    isError={incorrectFields.includes("login")}
                    onClick={() => {}}
                    otherProps={{}}
                />
                <Input
                    type="text"
                    name="email"
                    placeholder={t("email")}
                    onChange={changeHandler}
                    value={form.email}
                    isError={incorrectFields.includes("email")}
                    onClick={() => {}}
                    otherProps={{}}
                />
                <div
                    className={classNames("input-field input-field__phone-confirm", {
                        error: incorrectFields.includes("phone"),
                    })}
                >
                    <IMaskInput
                        //@ts-ignore
                        type="text"
                        name="phone"
                        placeholder={t("phone")}
                        onChange={changeHandler}
                        value={form.phone}
                        mask="+{7}(000)000-00-00"
                        readOnly={isPhoneConfirmed}
                    />
                    {isPhoneConfirmed ? (
                        <div className="input-field__phone-confirm-succes">
                            {t("phone_confirm_success")}
                            <img src={checkIcon} alt="" />
                        </div>
                    ) : (
                        <button
                            onClick={showPhoneConfirmFormHandler}
                            className="input-field__phone-confirm-button"
                            disabled={!validatePhone(form.phone)}
                        >
                            {t("phone_confirm_btn")}
                        </button>
                    )}
                </div>
                <Input
                    type="text"
                    name="region"
                    placeholder={t("region")}
                    onClick={() => history.push("/psych/register?step=region")}
                    value={form.city ? `${form.city}, ${form.region}` : ""}
                    isError={incorrectFields.includes("region")}
                    readOnly
                    otherProps={{}}
                    onChange={() => {}}
                />
                {regionID ? (
                    <Input
                        type="text"
                        name="selecting_institution"
                        placeholder={t("selecting_institution")}
                        onClick={() => history.push("/psych/register?step=institution")}
                        value={
                            form.institution
                                ? `${form.institution} ${form.institutionID && `(ID: ${form.institutionID})`}`
                                : ""
                        }
                        isError={incorrectFields.includes("institution")}
                        readOnly
                        otherProps={{}}
                        onChange={() => {}}
                    />
                ) : (
                    <Input
                        type="text"
                        name="institution"
                        placeholder={t("institution")}
                        onChange={changeHandler}
                        value={form.institution}
                        isError={incorrectFields.includes("institution")}
                        onClick={() => {}}
                        otherProps={{}}
                    />
                )}
            </div>

            <TermsAndConditions
                firstCheckboxChecked={firstCheckboxChecked}
                secondCheckboxChecked={secondCheckboxChecked}
                thirdCheckboxChecked={thirdCheckboxChecked}
                fourthCheckboxChecked={fourthCheckboxChecked}
                setFirstCheckboxChecked={setFirstCheckboxChecked}
                setSecondCheckboxChecked={setSecondCheckboxChecked}
                setThirdCheckboxChecked={setThirdCheckboxChecked}
                setFourthCheckboxChecked={setFourthCheckboxChecked}
                terms={terms}
                policy={policy}
                onRegister={registerHandler}
            />
        </>
    );
};
