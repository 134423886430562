import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useFetchEljurUser } from "hooks/useQuery";
import { Loader } from "components/Loader";

export const EljurPage = () => {
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const authToken = searchParams.get("authToken");
    const vendor = searchParams.get("vendor");
    const { eljurUserData, eljurUserError, eljurUserIsLoading } = useFetchEljurUser(authToken, vendor);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (eljurUserData) {
            if (eljurUserData.isUserRegistered) {
                history.push("/dashboard");
            } else if (eljurUserData.role === "psych") {
                history.push(`/psych/register?authToken=${authToken}&vendor=${vendor}`);
            } else if (eljurUserData.role === "pupil") {
                if (eljurUserData.userData.classId) {
                    history.push(`/pupil/${eljurUserData.userData.classId}?authToken=${authToken}&vendor=${vendor}`);
                } else {
                    setErrorMessage("Класс для данного пользователя ещё не создан");
                }
            }
        }
    }, [eljurUserData, authToken, vendor, history]);

    if (authToken && eljurUserIsLoading) {
        return <Loader />;
    }

    return (
        <div className="main">
            <div className="page auth-page">
                <header className="page__header">
                    <p className="page__title">Авторизация через ЭлЖур</p>
                </header>

                {!authToken && <p className="data-error">Передан некорректный токен пользователя</p>}

                {eljurUserError && <p className="data-error">{eljurUserError.message}</p>}
                {errorMessage && <p className="data-error">{errorMessage}</p>}
            </div>
        </div>
    );
};
